function AdminExperimentsCtrl(SETTINGS, $log, $mdDialog, $http, $scope, $document, $timeout, ExperimentUploader, jobSubmit) {
  this.experiments = [];

  const fetchJobCount = (experimentName) => {
    return $http.get(SETTINGS.apiEndpoint + 'jobs/getJobCount', { params: { experimentName: experimentName } })
      .then((response) => {
        return response.data.count;
      })
      .catch((err) => {
        $log.error('Error fetching job count for experiment:', experimentName, err);
        return null;
      });
  };

  this.fetchExperiments = () => {
    $http.get(SETTINGS.apiEndpoint + 'jobs/getExperiments')
      .then((response) => {
        this.experiments = response.data;

        const jobCountPromises = this.experiments.map((experiment) => {
          return fetchJobCount(experiment.experiment_name).then((count) => {
            experiment.jobCount = count;
          });
        });

        return Promise.all(jobCountPromises);
      })
      .catch((err) => {
        $log.error('Error fetching job count for experiment: ', err);
        throw err;
      });
  };

  this.fetchExperiments();

  this.deleteExperiment = (experimentName) => {
    const confirm = $mdDialog.confirm()
      .title('Would you like to delete this experiment?')
      .textContent('All mappings associated with this experiment will be permanently deleted.')
      .ariaLabel('Delete experiment')
      .ok('Yes, delete it')
      .cancel('No, cancel');

    $mdDialog.show(confirm).then(() => {
      $http.post(SETTINGS.apiEndpoint + 'jobs/deleteExperiment', { experimentName: experimentName })
        .then(() => {
          this.experiments = this.experiments.filter(experiment => experiment.experiment_name !== experimentName);
        })
        .catch((err) => {
          $log.error('Error deleting experiment:', experimentName, err);
        });
    });
  };

  this.showExperimentList = true;
  this.selectedExperimentDetail = null;
  this.sampleIdSearch = '';
  this.subjectIdSearch = '';
  this.sortKey = '';
  this.reverseSort = false;
  this.currentPage = 1;
  this.sortKey = null;
  this.sortOrder = 'default';
  this.totalCount = 0;
  this.maxPages = 1;
  this.parentJob = jobSubmit.parentJob;
  this.hasNextPage = false;

  this.getSampleCount = (experimentName) => {
    $http.get(SETTINGS.apiEndpoint + 'jobs/getSampleCount', { params: { experimentName: experimentName } })
      .then((response) => {
        this.totalCount = response.data.totalCount;
        this.maxPages = Math.ceil(this.totalCount / 10);
      })
      .catch((err) => {
        $log.error('Error fetching experiment count:', err);
        this.totalCount = 0;
        this.maxPages = 1;
        this.currentPage = 1;
      });
  };

  this.viewExperimentDetails = function (experimentName) {
    this.selectedExperimentDetail = { name: experimentName };
    this.showExperimentList = false;
    this.fetchExperimentDetails(experimentName);
    this.getSampleCount(experimentName);
  };

  this.backToExperimentList = function () {
    this.showExperimentList = true;
    this.selectedExperimentDetail = null;
    this.sampleIdSearch = '';
    this.subjectIdSearch = '';
    this.sortKey = '';
    this.reverseSort = false;
    this.experimentDetails = [];
    this.covariateKeys = [];
    this.currentPage = 1;
    this.sortKey = null;
    this.sortOrder = 'default';
    this.uploadCardVisible = false;
    this.files = [];
  };

  this.debounceSearch = null;

  this.handleSearch = function () {
    if (this.debounceSearch) {
      $timeout.cancel(this.debounceSearch);
    }
    this.debounceSearch = $timeout(() => {
      this.currentPage = 1;
      this.getSampleCount(this.selectedExperimentDetail.name);
      this.fetchExperimentDetails(this.selectedExperimentDetail.name, 1);
    }, 200);
  };

  this.toggleSort = function (key) {
    if (this.sortKey === key) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : (this.sortOrder === 'desc' ? 'default' : 'asc');
    } else {
      this.sortKey = key;
      this.sortOrder = 'asc';
    }
    this.fetchExperimentDetails(this.selectedExperimentDetail.name, this.currentPage);
  };

  this.fetchExperimentDetails = function (experimentName, page) {
    this.currentPage = page || this.currentPage;
    const url = SETTINGS.apiEndpoint + 'jobs/getExperimentDetails';
    const params = {
      params: {
        experimentName: experimentName,
        limit: 10,
        page: this.currentPage,
        searchSampleId: this.sampleIdSearch,
        searchSubjectId: this.subjectIdSearch,
        sortKey: this.sortKey,
        sortOrder: this.sortOrder
      }
    };

    $http.get(url, params).then(response => {
      if (response.data && Array.isArray(response.data.data)) {
        let allCovariateKeys = new Set();

        this.experimentDetails = response.data.data.map(item => {
          Object.keys(item.covariates || {}).forEach(key => allCovariateKeys.add(key));
          return Object.assign({}, item, item.covariates);
        });

        this.hasNextPage = response.data.hasNextPage;

        this.covariateKeys = Array.from(allCovariateKeys);
      } else {
        this.experimentDetails = [];
        this.covariateKeys = [];
        this.hasNextPage = false;
        $log.error('Unexpected response format:', response);
      }
    }).catch(error => {
      $log.error('Error fetching experiment details:', error);
      this.hasNextPage = false;
    });
  };

  this.disablePrevious = () => {
    return this.currentPage <= 1;
  };

  this.disableNext = () => {
    return !this.hasNextPage;
  };

  this.goToPage = function (page) {
    if (page >= 1 && page <= this.maxPages) {
      this.currentPage = page;
      this.fetchExperimentDetails(this.selectedExperimentDetail.name, page);
    }
  };

  this.openAddExperimentModal = function () {
    $mdDialog.show({
      templateUrl: 'admin/admin.experiments.dialog.tpl.html',
      controller: 'AddExperimentDialogController',
      controllerAs: '$ctrl',
      bindToController: true,
      clickOutsideToClose: true,
      locals: {
        parentCtrl: this
      }
    });
  };

  this.uploadCardVisible = false;
  this.files = [];
  this.tsvData = [];
  this.displayTsvData = [];
  this.tsvHeaders = ["Experiment Name", "Sample ID", "Subject ID"];
  this.confirmationChecked = false;
  this.selectedExperiment = null;

  this.previewFile = (files, event) => {
    if (!files || !files.length) {
      return;
    }

    if (files.length > 1) {
      alert("Can only upload one file at a time.");
      this.files = [];
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    let delimiter = '\t';
    let headerColumns = [];
    let lines;

    reader.onload = (e) => {
      const contents = e.target.result;
      this.tsvData = [];

      if (file.name.endsWith('.csv') || file.name.endsWith('.tsv')) {
        if (contents) {
          lines = contents.split('\n');
          if (lines.length < 2) {
            $log.error('The file appears to be empty or does not have header data.');
            return;
          }
          if (file.name.endsWith('.csv')) {
            delimiter = ',';
          }

          headerColumns = lines[0].split(delimiter).map(str => str.trim());
          const requiredHeaders = ["Experiment Name", "Sample ID", "Subject ID"];
          const hasRequiredHeaders = requiredHeaders.every(header => headerColumns.includes(header));

          if (!hasRequiredHeaders) {
            alert("Your file is missing one or more of the required headers.");
            return;
          }

          this.tsvHeaders = headerColumns;

          for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === '') {
              continue;
            }
            const columns = lines[i].split(delimiter).map(str => str.trim());
            this.tsvData.push(columns);
          }
        }
      }

      let filteredData = this.tsvData.filter(row => {
        return row[0] === this.selectedExperimentDetail.name;
      });

      this.displayTsvData = [];
      const maxRowsToDisplay = 10;

      for (let i = 0; i < filteredData.length; i++) {
        if (i < maxRowsToDisplay) {
          this.displayTsvData.push(filteredData[i]);
        } else if (i === maxRowsToDisplay && filteredData.length > maxRowsToDisplay + 1) {
          if (filteredData.length === maxRowsToDisplay + 2) {
            this.displayTsvData.push(filteredData[i]);
          } else {
            this.displayTsvData.push(['...', '...', '...']);
          }
        } else if (i === filteredData.length - 1 && filteredData.length > maxRowsToDisplay + 2) {
          this.displayTsvData.push(filteredData[i]);
        }
      }

      this.selectedExperiment = this.selectedExperimentDetail.name;
      this.files = files;
      this.uploadCardVisible = true;
      $scope.$apply();
    };

    reader.readAsText(file);
  };

  this.submitUpload = () => {
    if (!this.parentJob) {
      console.error("Something went wrong; parentJob is falsy.");
      return;
    }

    if (!(this.confirmationChecked && this.selectedExperiment && this.files.length > 0)) {
      alert("Please uncheck the confirmation box, and ensure you have selected an experiment before uploading files.");
      return;
    }

    ExperimentUploader.upload(this.files[0], this.parentJob, this.selectedExperiment)
      .then(() => {
        if (this.files[0].serverError) {
          alert(`${this.files[0].serverError}\nPlease clear the file or try again.`);
        } else {
          this.fetchExperimentDetails(this.selectedExperimentDetail.name);
          this.fetchExperiments();

          this.uploadCardVisible = false;
          this.files = [];

          this.confirmationChecked = false;
          this.tsvData = [];
          this.displayTsvData = [];
          this.selectedExperiment = null;
        }
      })
      .catch((error) => {
        $log.error(error);
        alert("Failed to upload the file. Please try again.");
      });
  };

  this.isUploading = () => {
    return ExperimentUploader.running;
  };

  this.cancelUpload = () => {
    this.uploadCardVisible = false;
    this.files = [];
    this.selectedExperiment = '';
    this.confirmationChecked = false;
    this.tsvData = [];
    this.displayTsvData = [];
    this.experimentNames = [];
    jobSubmit.clearExperiment();
    ExperimentUploader.cancelUpload();
  };

}

function AddExperimentDialogController($mdDialog, $log, ExperimentUploader, jobSubmit, $scope) {
  this.selectedExperiment = null;
  this.searchText = '';
  this.files = [];
  this.experimentNames = [];
  this.confirmationChecked = false;
  this.tsvData = [];
  this.displayTsvData = [];
  this.tsvHeaders = ["Experiment Name", "Sample ID", "Subject ID"];
  this.parentJob = jobSubmit.parentJob;

  this.querySearch = function (query) {
    if (!query) {
      return this.experimentNames;
    }

    if (this.files && this.files.length > 0) {
      return this.experimentNames.filter(function (name) {
        return name.toLowerCase().includes(query.toLowerCase());
      });
    }
  };

  this.previewFile = (files) => {
    if (!files || !files.length) {
      return;
    }

    if (files.length > 1) {
      alert("Can only upload one file at a time.");
      this.files = [];
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    let delimiter = '\t';
    let headerColumns = [];
    let experimentName = '';
    let lines;
    this.selectedExperiment = '';
    this.searchText = '';
    this.confirmationChecked = false;

    reader.onload = (e) => {
      const contents = e.target.result;

      this.tsvData = [];
      this.displayTsvData = [];

      const experimentNames = new Set();
      const maxRowsToDisplay = 10;

      if (file.name.endsWith('.csv') || file.name.endsWith('.tsv')) {
        if (contents) {
          lines = contents.split('\n');
          if (lines.length < 2) {
            $log.error('The file appears to be empty or does not have header data.');
            return;
          }
          if (file.name.endsWith('.csv')) {
            delimiter = ',';
          }

          headerColumns = lines[0].split(delimiter).map(str => str.trim());
          const requiredHeaders = ["Experiment Name", "Sample ID", "Subject ID"];
          const hasRequiredHeaders = requiredHeaders.every(header => headerColumns.includes(header));

          if (!hasRequiredHeaders) {
            alert("Your file is missing one or more of the required headers: Experiment Name, Sample ID, Subject ID.");
            return;
          }

          this.tsvHeaders = headerColumns;
          experimentName = headerColumns[0];

          for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === '') {
              continue;
            }
            const columns = lines[i].split(delimiter).map(str => str.trim());
            this.tsvData.push(columns);
            experimentNames.add(columns[0]);

            if (i <= maxRowsToDisplay) {
              this.displayTsvData.push(columns);
            } else if (i === maxRowsToDisplay + 1 && lines.length > maxRowsToDisplay + 1) {
              if (lines.length === maxRowsToDisplay + 2) {
                this.displayTsvData.push(columns);
              } else {
                this.displayTsvData.push(['...', '...', '...']);
              }
            } else if (i === lines.length - 1 && lines.length > maxRowsToDisplay + 2) {
              this.displayTsvData.push(columns);
            }
          }

          ExperimentUploader.experimentName = experimentName;
        }
      }

      if (experimentNames.size === 1) {
        this.selectedExperiment = [...experimentNames][0];
        this.searchText = this.selectedExperiment;
      }
      this.experimentNames = [...experimentNames];

      $scope.$apply();
    };

    reader.readAsText(file);
  };

  this.selectedItemChange = function (item) {
    if (this.files && this.files.length > 0) {
      if (!item) {
        return this.populateTable(this.tsvData);
      }

      return this.populateTable(this.tsvData.filter((row) => row[0] === item));
    }

    if (!item) {
      this.tsvData = [];
      this.displayTsvData = [];
      this.experimentNames = [];
      this.confirmationChecked = false;
      jobSubmit.clearExperiment();
      return;
    }

    if (this.experimentNames.includes(item)) {
      this.populateTable(this.tsvData.filter((row) => row[0] === item));
    } else {
      this.fetchExperimentDetails(item).then(data => {
        this.tsvData = data;
        this.populateTable(data);
      });
    }
  };

  this.populateTable = function (data) {
    this.displayTsvData = [];

    const maxRowsToDisplay = 10;

    if (!data) {
      // currently sends warning if no data is being populated, but could implement loading bar while file is being read from reader.readAsText(file);
      $log.warn('No data available to populate the table, the file could still be loading');
      return;
    }

    for (let i = 0; i < data.length; i++) {
      if (i < maxRowsToDisplay) {
        this.displayTsvData.push(data[i]);
      } else if (i === maxRowsToDisplay && data.length > maxRowsToDisplay + 1) {
        this.displayTsvData.push(['...', '...', '...']);
        i = data.length - 2;
      } else if (i === data.length - 1) {
        this.displayTsvData.push(data[i]);
      }
    }
  };

  this.cancelUpload = () => {
    this.files = [];
    this.selectedExperiment = '';
    this.searchText = '';
    this.confirmationChecked = false;
    this.tsvData = [];
    this.displayTsvData = [];
    this.experimentNames = [];
    jobSubmit.clearExperiment();
    ExperimentUploader.cancelUpload();
  };

  this.cancelAllUploads = () => {
    this.files = [];
  };

  this.isUploading = () => {
    return ExperimentUploader.running;
  };

  this.isFailed = () => {
    return ExperimentUploader.file && ExperimentUploader.file.serverError;
  };

  this.uploadFiles = () => {
    if (!this.parentJob) {
      console.error("Something went wrong; parentJob is falsy.");
      return;
    }

    if (!(this.confirmationChecked && this.selectedExperiment && this.files.length > 0)) {
      alert("Please uncheck the confirmation box, and ensure you have selected an experiment before uploading files.");
      return;
    }

    if (this.files.length != 1) {
      alert("Currently only one file is supported. Please ensure only 1 file selected.");
      return;
    }

    ExperimentUploader.upload(this.files[0], this.parentJob, this.selectedExperiment)
      .then(() => {
        if (this.files[0].serverError) {
          alert(`${this.files[0].serverError}\nPlease clear the file or click 'Next' again to skip upload`);
        } else {
          this.parentCtrl.fetchExperiments();
          $mdDialog.hide();
        }
      })
      .catch((error) => {
        $log.error(error);
        alert(`${this.files[0].serverError}\nPlease clear the file or click 'Next' again to skip upload`);
      });
  };

  this.cancel = function () {
    $mdDialog.cancel();
  };
}

angular.module('sq.admin.experiments.component', ['sq.jobs.experiment.upload.service', 'ngSanitize'])
  .controller('AddExperimentDialogController', AddExperimentDialogController)
  .component('sqAdminExperiments', {
    templateUrl: 'admin/admin.experiments.tpl.html',
    controller: AdminExperimentsCtrl,
    controllerAs: '$ctrl',
  });
